var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pa-2"},[_c('v-row',[_c('v-btn',{attrs:{"elevation":"2","fab":""},on:{"click":() => {
            _vm.showLeadsDuplicates = !_vm.showLeadsDuplicates;
          }}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),_c('div',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"4","justify":"center","align":"center"}},[(_vm.isLoadingName)?_c('div',{staticClass:"kanban-bg-color pa-2"},[_c('header',[_c('span',[_vm._v(" Nombres duplicados ")])]),_c('ul',{staticClass:"mx-0 pa-0"},_vm._l((10),function(item,index){return _c('div',{key:index},[_c('v-skeleton-loader',{staticClass:"my-2",attrs:{"type":"list-item-avatar-two-line,list-item, actions"}})],1)}),0)]):_c('div',{staticClass:"kanban-bg-color pa-2"},[_c('header',[_c('span',[_vm._v(" Nombres duplicados ")])]),_c('ul',{staticClass:"mx-0 pa-0"},[_vm._l((_vm.names),function(item,i){return _c('div',{key:i},_vm._l((item.leads),function(lead,n){return _c('div',{key:n,on:{"click":function($event){return _vm.setActualLead(lead._id)}}},[_c('LeadDuplicateCard',{staticClass:"my-2",attrs:{"lead":lead,"brokerName":_vm.getBrokerName(lead),"srcBrokerImage":lead.internal_broker
                      ? _vm.brokerImageFormat(lead.internal_broker)
                      : _vm.brokerImageFormat(lead.internal_admin),"type":'name'}})],1)}),0)}),(_vm.loadMoreAvailable(_vm.optionsNames))?_c('div',[_c('v-btn',{staticClass:"my-4 py-4",attrs:{"color":"primary","disabled":_vm.loadingMoreNames,"loading":_vm.loadingMoreNames,"block":""},on:{"click":function($event){return _vm.loadMoreLeads('name', _vm.optionsNames)}}},[_vm._v(" Cargar más "),_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-plus-circle ")])],1)],1):_vm._e()],2)])]),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"4","justify":"center","align":"center"}},[(_vm.isLoadingEmail)?_c('div',{staticClass:"kanban-bg-color pa-2"},[_c('header',[_c('span',[_vm._v(" Correos duplicados ")])]),_c('ul',{staticClass:"mx-0 pa-0"},_vm._l((10),function(item,index){return _c('div',{key:index},[_c('v-skeleton-loader',{staticClass:"my-2",attrs:{"type":"list-item-avatar-two-line,list-item, actions"}})],1)}),0)]):_c('div',{staticClass:"kanban-bg-color pa-2"},[_c('header',[_c('span',[_vm._v(" Correos duplicados ")])]),_c('ul',{staticClass:"mx-0 pa-0"},[_vm._l((_vm.emails),function(item,i){return _c('div',{key:i},_vm._l((item.leads),function(lead,n){return _c('div',{key:n,on:{"click":function($event){return _vm.setActualLead(lead._id)}}},[_c('LeadDuplicateCard',{staticClass:"my-2",attrs:{"lead":lead,"brokerName":_vm.getBrokerName(lead),"srcBrokerImage":lead.internal_broker
                      ? _vm.brokerImageFormat(lead.internal_broker)
                      : _vm.brokerImageFormat(lead.internal_admin),"type":'email'}})],1)}),0)}),(_vm.loadMoreAvailable(_vm.optionsEmails))?_c('div',[_c('v-btn',{staticClass:"my-4 py-4",attrs:{"color":"primary","disabled":_vm.loadingMoreEmails,"loading":_vm.loadingMoreEmails,"block":""},on:{"click":function($event){return _vm.loadMoreLeads('email', _vm.optionsEmails)}}},[_vm._v(" Cargar más "),_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-plus-circle ")])],1)],1):_vm._e()],2)])]),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"4","justify":"center","align":"center"}},[(_vm.isLoadingPhone)?_c('div',{staticClass:"kanban-bg-color pa-2"},[_c('header',[_c('span',[_vm._v(" Teléfonos duplicados ")])]),_c('ul',{staticClass:"mx-0 pa-0"},_vm._l((10),function(item,index){return _c('div',{key:index},[_c('v-skeleton-loader',{staticClass:"my-2",attrs:{"type":"list-item-avatar-two-line,list-item, actions"}})],1)}),0)]):_c('div',{staticClass:"kanban-bg-color pa-2"},[_c('header',[_c('span',[_vm._v(" Teléfonos duplicados ")])]),_c('ul',{staticClass:"mx-0 pa-0"},[_vm._l((_vm.phones),function(item,i){return _c('div',{key:i},_vm._l((item.leads),function(lead,n){return _c('div',{key:n,on:{"click":function($event){return _vm.setActualLead(lead._id)}}},[_c('LeadDuplicateCard',{staticClass:"my-2",attrs:{"lead":lead,"brokerName":_vm.getBrokerName(lead),"srcBrokerImage":lead.internal_broker
                      ? _vm.brokerImageFormat(lead.internal_broker)
                      : _vm.brokerImageFormat(lead.internal_admin),"type":'phone'}})],1)}),0)}),(_vm.loadMoreAvailable(_vm.optionsPhones))?_c('div',[_c('v-btn',{staticClass:"my-4 py-4",attrs:{"color":"primary","disabled":_vm.loadingMorePhones,"loading":_vm.loadingMorePhones,"block":""},on:{"click":function($event){return _vm.loadMoreLeads('phone', _vm.optionsPhones)}}},[_vm._v(" Cargar más "),_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-plus-circle ")])],1)],1):_vm._e()],2)])])],1)],1),_c('lead-detail-modal',{attrs:{"dialog":_vm.isDialogActive},on:{"setDialog":_vm.setDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }