<template>
  <div>
    <div class="pa-2">
      <v-row>
        <v-btn
          elevation="2"
          fab
          @click="
            () => {
              showLeadsDuplicates = !showLeadsDuplicates;
            }
          "
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>
    </div>
    <div>
      <v-row class="mx-2">
        <v-col cols="4" class="pa-0 ma-0" justify="center" align="center">
          <div v-if="isLoadingName" class="kanban-bg-color pa-2">
            <header>
              <span>
                Nombres duplicados
              </span>
            </header>
            <ul class="mx-0 pa-0">
              <div v-for="(item, index) in 10" :key="index">
                <v-skeleton-loader
                  class="my-2"
                  type="list-item-avatar-two-line,list-item, actions"
                ></v-skeleton-loader>
              </div>
            </ul>
          </div>
          <div v-else class="kanban-bg-color pa-2">
            <header>
              <span>
                Nombres duplicados
              </span>
            </header>
            <ul class="mx-0 pa-0">
              <div v-for="(item, i) in names" :key="i">
                <div
                  v-for="(lead, n) in item.leads"
                  :key="n"
                  @click="setActualLead(lead._id)"
                >
                  <LeadDuplicateCard
                    class="my-2"
                    :lead="lead"
                    :brokerName="getBrokerName(lead)"
                    :srcBrokerImage="
                      lead.internal_broker
                        ? brokerImageFormat(lead.internal_broker)
                        : brokerImageFormat(lead.internal_admin)
                    "
                    :type="'name'"
                  />
                </div>
              </div>
              <div v-if="loadMoreAvailable(optionsNames)">
                <v-btn
                  color="primary"
                  class="my-4 py-4"
                  :disabled="loadingMoreNames"
                  :loading="loadingMoreNames"
                  @click="loadMoreLeads('name', optionsNames)"
                  block
                >
                  Cargar más
                  <v-icon class="mx-2">
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </div>
            </ul>
          </div>
        </v-col>
        <v-col cols="4" class="pa-0 ma-0" justify="center" align="center">
          <div v-if="isLoadingEmail" class="kanban-bg-color pa-2">
            <header>
              <span>
                Correos duplicados
              </span>
            </header>
            <ul class="mx-0 pa-0">
              <div v-for="(item, index) in 10" :key="index">
                <v-skeleton-loader
                  class="my-2"
                  type="list-item-avatar-two-line,list-item, actions"
                ></v-skeleton-loader>
              </div>
            </ul>
          </div>
          <div v-else class="kanban-bg-color pa-2">
            <header>
              <span>
                Correos duplicados
              </span>
            </header>
            <ul class="mx-0 pa-0">
              <div v-for="(item, i) in emails" :key="i">
                <div
                  v-for="(lead, n) in item.leads"
                  :key="n"
                  @click="setActualLead(lead._id)"
                >
                  <LeadDuplicateCard
                    class="my-2"
                    :lead="lead"
                    :brokerName="getBrokerName(lead)"
                    :srcBrokerImage="
                      lead.internal_broker
                        ? brokerImageFormat(lead.internal_broker)
                        : brokerImageFormat(lead.internal_admin)
                    "
                    :type="'email'"
                  />
                </div>
              </div>
              <div v-if="loadMoreAvailable(optionsEmails)">
                <v-btn
                  color="primary"
                  class="my-4 py-4"
                  :disabled="loadingMoreEmails"
                  :loading="loadingMoreEmails"
                  @click="loadMoreLeads('email', optionsEmails)"
                  block
                >
                  Cargar más
                  <v-icon class="mx-2">
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </div>
            </ul>
          </div>
        </v-col>
        <v-col cols="4" class="pa-0 ma-0" justify="center" align="center">
          <div v-if="isLoadingPhone" class="kanban-bg-color pa-2">
            <header>
              <span>
                Teléfonos duplicados
              </span>
            </header>
            <ul class="mx-0 pa-0">
              <div v-for="(item, index) in 10" :key="index">
                <v-skeleton-loader
                  class="my-2"
                  type="list-item-avatar-two-line,list-item, actions"
                ></v-skeleton-loader>
              </div>
            </ul>
          </div>
          <div v-else class="kanban-bg-color pa-2">
            <header>
              <span>
                Teléfonos duplicados
              </span>
            </header>
            <ul class="mx-0 pa-0">
              <div v-for="(item, i) in phones" :key="i">
                <div
                  v-for="(lead, n) in item.leads"
                  :key="n"
                  @click="setActualLead(lead._id)"
                >
                  <LeadDuplicateCard
                    class="my-2"
                    :lead="lead"
                    :brokerName="getBrokerName(lead)"
                    :srcBrokerImage="
                      lead.internal_broker
                        ? brokerImageFormat(lead.internal_broker)
                        : brokerImageFormat(lead.internal_admin)
                    "
                    :type="'phone'"
                  />
                </div>
              </div>
              <div v-if="loadMoreAvailable(optionsPhones)">
                <v-btn
                  color="primary"
                  class="my-4 py-4"
                  :disabled="loadingMorePhones"
                  :loading="loadingMorePhones"
                  @click="loadMoreLeads('phone', optionsPhones)"
                  block
                >
                  Cargar más
                  <v-icon class="mx-2">
                    mdi-plus-circle
                  </v-icon>
                </v-btn>
              </div>
            </ul>
          </div>
        </v-col>
      </v-row>
    </div>
    <lead-detail-modal :dialog="isDialogActive" @setDialog="setDialog" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
moment.locale("es");
export default {
  components: {
    LeadDuplicateCard: () => import("@/components/lead/LeadDuplicateCard.vue"),
    LeadDetailModal: () => import("@/components/lead/LeadDetailModal.vue")
  },
  data() {
    return {
      isDialogActive: false,
      isLoadingEmail: false,
      isLoadingName: false,
      isLoadingPhone: false,
      optionsNames: null,
      optionsPhones: null,
      optionsEmails: null,
      loadingMoreNames: false,
      loadingMoreEmails: false,
      loadingMorePhones: false,
      names: [],
      phones: [],
      emails: []
    };
  },
  mounted() {
    this.getLeadsDuplicates();
  },
  computed: {
    ...mapState({
      leadsDuplicatesSt: state => state.leads.leadsDuplicates,
      leadsDuplicatesLoadingSt: state => state.leads.leadsDuplicatesLoading
    }),
    showLeadsDuplicates: {
      get() {
        return this.$store.state.leads.filterShowLeadsDuplicates;
      },
      set(newValue) {
        this.$store.commit("leads/setfilterShowLeadsDuplicates", newValue);
      }
    }
  },
  filters: {
    percentageFormat(percentage) {
      if (!percentage || isNaN(percentage)) return 0;
      let percentageParsed = parseInt(percentage);
      return percentageParsed;
    },
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD-MMM-YYYY h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    },

    interestFormat(targetAction, propertyType, interestedZones) {
      let htmlInterested = "";
      // let propertyTypeTest = ['Departamento', 'Departamento 2', 'Departamento 3']

      if (targetAction) htmlInterested += targetAction;
      if (propertyType && propertyType.length > 0) {
        htmlInterested += " " + propertyType.toString();
      }
      if (interestedZones) htmlInterested += " " + interestedZones;
      return htmlInterested;
    }
  },
  methods: {
    ...mapActions({
      fetchGetLeadsDuplicatesSt: "leads/fetchGetLeadsDuplicates",
      fetchActualLead: "leads/fetchActualLead"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    getSizeSlide() {
      let size = window.screen.availWidth;
      let final = Math.round(size / 100);
      return `${final * 92}`;
    },
    getSafeNestedField(fn, defaultVal) {
      try {
        return fn();
      } catch (e) {
        return defaultVal;
      }
    },
    brokerImageFormat(broker) {
      if (!(broker && broker.name)) return "";
      let brokerSrcImg = "";
      let brokerName = "";
      if (broker.name) brokerName = broker.name;
      if (
        broker.media &&
        broker.media.featured_image &&
        broker.media.featured_image.src
      )
        brokerSrcImg = broker.media.featured_image.src;
      if (!brokerName) brokerName = "C28";
      if (!brokerSrcImg)
        brokerSrcImg =
          "https://dummyimage.com/400x200/262d3c/fff&text=" +
          brokerName.substring(0, 2);

      return brokerSrcImg;
    },
    getBrokerName(lead) {
      if (lead.internal_broker) {
        return lead.internal_broker.name;
      } else if (lead.internal_admin) {
        return lead.internal_admin.name;
      } else {
        return "";
      }
    },

    getLeadsDuplicates() {
      this.isLoadingName = true;
      this.isLoadingEmail = true;
      this.isLoadingPhone = true;
      this.fetchGetLeadsDuplicatesSt({
        field: "name",
        page: 0
      }).then(value => {
        this.names = value.Lead;
        this.optionsNames = value.Options;
        this.isLoadingName = false;
      });
      this.fetchGetLeadsDuplicatesSt({
        field: "email",
        page: 0
      }).then(value => {
        this.emails = value.Lead;
        this.optionsEmails = value.Options;
        this.isLoadingEmail = false;
      });
      this.fetchGetLeadsDuplicatesSt({
        field: "phone",
        page: 0
      }).then(value => {
        this.phones = value.Lead;
        this.optionsPhones = value.Options;
        this.isLoadingPhone = false;
      });
    },
    loadMoreAvailable(data) {
      return data.page < Math.ceil(data.pages) - 1;
    },
    loadMoreLeads(type, data) {
      switch (type) {
        case "email":
          this.loadingMoreEmails = true;
          this.fetchGetLeadsDuplicatesSt({
            field: "email",
            page: data.page + 1
          }).then(value => {
            this.emails.push(...value.Lead);
            this.optionsEmails = value.Options;
            this.loadingMoreEmails = false;
          });
          break;
        case "phone":
          this.loadingMorePhones = true;
          this.fetchGetLeadsDuplicatesSt({
            field: "phone",
            page: data.page + 1
          }).then(value => {
            this.phones = value.Lead;
            this.optionsPhones = value.Options;
            this.loadingMorePhones = false;
          });
          break;
        default:
          this.loadingMoreNames = true;
          this.fetchGetLeadsDuplicatesSt({
            field: "name",
            page: data.page + 1
          }).then(value => {
            this.names.push(...value.Lead);
            this.optionsNames = value.Options;
            this.loadingMoreNames = false;
          });
          break;
      }
    },
    setActualLead(leadId) {
      // si es usuario es marketing no realizar ninguna acción
      if (this.userRole === "marketing") return;
      this.setLoading(true);
      this.setDialog(true);
      this.fetchActualLead(leadId)
        .then()
        .finally(() => {
          setTimeout(() => {
            this.setLoading(false);
          }, 1000);
        });
    },
    setDialog(isActive) {
      this.isDialogActive = isActive;
    }
  }
};
</script>

<style scoped>
.kanban-bg-color {
  margin-right: 5px;
  margin-left: 5px;
  background-color: #e0f8ff;
}
</style>
